<template>
  <vx-card>
    <form-wizard
      color="rgba(var(--vs-primary), 1)"
      :title="'ZONA DE PAGOS'"
      :subtitle="'Formulario para crear y editar zona de pagos'"
      :hide-buttons="true"
    >
      <div class="vx-row">
        <vs-divider class="vx-col w-full mt-5" position="left" color="dark">Información de zona de pagos</vs-divider>
        <div class="vx-col md:w-1/2 w-full">
          <label for="category" class="w-full select-large">Categoría</label>
          <v-select
            id="category"
            name="category"
            label="name"
            index="id"
            :options="categories"
            :placeholder="'Seleccione...'"
            :searchable="true"
            v-model="entryModel.category"
            v-validate="'required'"
            class="w-full"
            @input="getSubCategories(entryModel.category)"
          />
          <span class="text-danger">{{ errors.first("category") }}</span>
          <br/>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label for="sub_category" class="w-full select-large">Sub-Categoría</label>
          <v-select
            id="sub_category"
            name="sub_category"
            label="name"
            index="id"
            :options="subcategories"
            :placeholder="'Seleccione...'"
            :searchable="true"
            v-model="entryModel.sub_category"
            class="w-full"
          />
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <label for="name" class="w-full select-large">Nombre</label>
          <vs-input
            id="name"
            name="name"
            v-model="entryModel.name"
            v-validate="'required'"
            class="w-full"
            autocomplete="off"
          />
          <span class="text-danger">{{ errors.first("name") }}</span>
          <br/>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label for="priority" class="w-full select-large">Prioridad</label>
          <vs-input
            type="number" id="priority" name="priority" min="1" max="10" v-model="entryModel.priority"
            v-validate="'required'" class="w-full"
          />
          <span class="text-danger">{{ errors.first("priority") }}</span>
          <br/>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mb-5">
          <label class="w-full select-large">Inserte el thumbnail de la página</label>
          <uploader
            :classFile="'file-upload-1'"
            :maxNumberOfFiles="1"
            @files-removed="removedFile('thumbnail')"
            @files-updated="thumbnailUpload"
            :downloadedFiles="entryModel.media.thumbnail"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full mt-5">
          <div class="flex flex-wrap justify-between">
            <vs-row vs-type="flex" vs-justify="flex-end">
              <vs-button color="danger" type="border" class="mb-2" @click="goToEntriesList">Cancelar</vs-button>
              <vs-button class="ml-3 mb-2" @click="createOrUpdateEntry" :disabled="!validateForm">Guardar</vs-button>
            </vs-row>
          </div>
        </div>
      </div>
    </form-wizard>
  </vx-card>
</template>

<script>
  import AttributeOptionsClient from "../../utils/attributeOptionsClient";
  import EntriesClient from "../../utils/entriesClient";
  import Notifier from "./../../utils/notification";
  import Configurations from "./../../utils/configurations";
  import Upload from "./../../utils/upload";

  import Uploader from "./../../components/Uploader.vue";

  import {FormWizard, TabContent} from "vue-form-wizard";
  import "vue-form-wizard/dist/vue-form-wizard.min.css";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import vSelect from "vue-select";
  import {Validator} from "vee-validate";

  let notifier = new Notifier();
  let configurations = new Configurations();
  const ENTRY_CLIENT = new EntriesClient("payments");
  const ATTRIBUTE_OPTIONS_CLIENT = new AttributeOptionsClient("payments");
  const UPLOAD = new Upload("resource");

  const requiredFields = ["name", "category", "prioridad"];
  let dict = requiredFields.reduce(
    (previousValue, currentValue) => {
      previousValue[currentValue] = {required: "* Este campo es requerido"};
      return previousValue
    },
    {}
  );
  Validator.localize("es", {custom: dict});

  export default {
    data() {
      return {
        resource: "payments",
        categories: [],
        subcategories: [],
        entryModel: {
          name: null,
          priority: 10,
          category: null,
          sub_category: null,
          media: {
            thumbnail: {
              url: null,
              meta: {
                title: null,
                description: null
              }
            },
          },
        }
      };
    },

    mounted() {
      if (this.$route.params.id !== undefined) {
        this.$vs.loading();
        this.loadEntryData(this.$route.params.id).then(() => {
          this.$vs.loading.close();
        });
      } else {
        this.$vs.loading();
        this.getCategories(false).then(() => {
          this.$vs.loading.close();
        });
      }
    },

    computed: {
      validateForm() {
        return !this.errors.any()
          && requiredFields.reduce((accumulator, currentValue) => {
            return accumulator && this.entryModel[currentValue] !== null;
          });
      }
    },

    methods: {
      async getCategories(showLoading = true) {
        if (showLoading) this.$vs.loading();

        await ATTRIBUTE_OPTIONS_CLIENT.categories({queryStrings: {representation_type: "simple"}})
          .then(response => {
            if (showLoading) this.$vs.loading.close();
            this.categories = response.data;
          })
          .catch(error => {
            if (showLoading) this.$vs.loading.close();
            notifier.notification("error");
          });
      },

      async getSubCategories(category, showLoading = true) {
        if (category !== null && category !== undefined && category !== "") {
          if (showLoading) this.$vs.loading();

          await ATTRIBUTE_OPTIONS_CLIENT.subCategories({
            pathParameters: {categoryId: category.id},
            queryStrings: {representation_type: "simple"}
          })
            .then(response => {
              this.subcategories = response.data;
              if (showLoading) this.$vs.loading.close();
            })
            .catch(error => {
              if (showLoading) this.$vs.loading.close();
              notifier.notification("error");
            });

        } else {
          this.subcategories = [];
          this.entryModel.sub_category = null;
        }
      },

      async loadEntryData(entryId) {
        await ENTRY_CLIENT.retrieve({pathParameters: {entryId: entryId}})
          .then(response => {
            this.getCategories(false)
              .then(() => {
                this.getSubCategories(response.data.category, false);
              })
              .then(() => {
                Object.assign(this.entryModel, response.data);
              });
          })
          .catch(error => {
            notifier.notification("error");
          });
      },

      async createOrUpdateEntry() {
        this.cleanModel();

        if (this.entryModel.id !== null && this.entryModel.id !== undefined && this.entryModel.id !== "") {
          this.$vs.loading();
          await ENTRY_CLIENT.update({pathParameters: {entryId: this.entryModel.id}, data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("updated");
              this.goToEntriesList();
            })
            .catch(error => {
              this.errors = error.response.data.errors;
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });

        } else {
          this.$vs.loading();
          await ENTRY_CLIENT.create({data: this.entryModel})
            .then(response => {
              this.$vs.loading.close();
              notifier.notification("created");
              this.goToEntriesList();
            })
            .catch(error => {
              this.errors = error.response.data.errors;
              this.$vs.loading.close();
              notifier.alertMessage("error");
            });
        }
      },

      async thumbnailUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          await UPLOAD.uploadFiles({
            queryStrings: {resource_type: this.resource, content_type: files[0].type},
            files: files
          })
            .then(response => {
              this.entryModel.media.thumbnail.url = response;
              this.entryModel.media.thumbnail.meta.title = files[0].meta.name;
              this.entryModel.media.thumbnail.meta.description = files[0].meta.caption;
            })
            .catch(error => {
              notifier.notification("error");
            });
        }
      },

      async removedFile(fileKey) {
        this.entryModel.media[fileKey] = {
          url: null,
          meta: {
            title: null,
            description: null
          }
        };
      },

      goToEntriesList() {
        this.$router.push({name: "payments"});
      },

      cleanModel() {
        Object.keys(this.entryModel).forEach(key => {
          if (this.entryModel[key] === null || this.entryModel[key] === "") {
            delete this.entryModel[key];
          }
        })
      },
    },
    components: {
      Uploader,
      FormWizard,
      TabContent,
      flatPickr,
      "v-select": vSelect
    }
  };
</script>
